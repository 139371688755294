/* App.css or your preferred style file */

/* Custom Modal Styles */
.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
  }
  
  .custom-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  .modal-header button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
  
  .modal-content {
    padding: 20px;
  }
  
  .modal-footer {
    border-top: 1px solid #ccc;
    padding: 10px;
    text-align: right;
  }
  
  .modal-footer button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  