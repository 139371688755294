.show{

}

.show select {
    margin: 0 6px;
    padding: 3px 16px 3px 0px;
    border-radius: 4px;
}
.show span {
    font-size: 14px;
}