img.subscription {
    background: transparent;
    filter: brightness(0) saturate(100%) invert(44%) sepia(95%) saturate(7091%) hue-rotate(352deg) brightness(91%) contrast(129%);
}

button.manage-active-buttons {
    background-color: #004686;
    border: 2px solid #004686;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    height: 44px;
    margin-right: 5px;
    padding: 0 20px;
    width: 120px;
}

table.upgrade-table-btn td button {
    color: #fff;
    padding: 0 22px;
    cursor: pointer;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 500;
    border: 0px solid #ffffff00;
    border-radius: 7px;
    height: 49px;
    background: linear-gradient(180deg, rgba(0, 72, 137, 1) 0%, rgb(1, 48, 90) 100%);
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 0;
}