.tooltip {
  position: relative;

}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;
  bottom: 27px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.subscription-btnn {
  display: flex;
  gap: 10px;
  align-items: center;
}

.subscription-btnn button {
  background-color: #b6c7db;
  color: #fff;
  padding: 0 22px;
  cursor: pointer;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 500;
  border: 0px solid #ffffff00;
  border-radius: 7px;
  height: 49px;
  background: linear-gradient(180deg, rgba(0, 72, 137, 1) 0%, rgb(1, 48, 90) 100%);
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}




.main-div-message-inquiey-notificationbar {
  z-index: 9999999 !important;
  position: relative;
  display: inline-block;
  height: auto;
  width: 239px;
  border-radius: 4px;
  margin-top: 65px;
  right: 0;
  position: absolute;
  left: auto;
}

.main-div-message-inquiey-notificationbar:before {
  content: '';
  width: 27px;
  height: 12px;
  position: absolute;
  top: 1px;
  right: 4px;
  background: #004686;
  transform: rotate(45deg);
}

.main-div-message-inquiey-notificationbar>div:first-child {
  display: flex;
  background-color: #004686;
  z-index: 9999999 !important;
  color: #fff;
  font-size: 7px;
  padding: 4px 6px;
}

.main-div-message-inquiey-notificationbar>div:first-child label {
  cursor: pointer;
  font-size: 15px;
  margin-left: 80px;
  padding: 9px;
}

.outer-div-message-inquiey-notificationbar {
  position: relative;
  padding: 4px;
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
  color: black;
  z-index: 9999999 !important;
  position: relative;
}



.message-inquiey-notificationbar {
  display: flex;
  flex-direction: column;
  z-index: 9999999 !important;
  position: relative;
  cursor: pointer;
}


.message-inquiey-notificationbar-inner {
  cursor: pointer;
  z-index: 9999999 !important;
  display: flex;
  flex-direction: column;
  background-color: #bdc8d5;
  border-bottom: 1px solid rgb(192 192 192);
  padding: 6px;
}


.message-inquiey-notificationbar-inner label {
  color: #121212;
  text-align: left;
  padding-left: 2px;
  cursor: pointer;
  font-size: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.message-inquiey-notificationbar-inner small {
  font-size: inherit;
  color: #494949;
  text-align: left;
  padding-left: 2px;
  cursor: pointer;
}




@media (max-width:600px) {
  .subscription-btnn {
    display: none;
  }
}