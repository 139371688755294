table tr td,
table tr th {
    font-size: 16px !important;
}

.add_user_btn button {
    background-color: #004686;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    margin-right: 5px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
}

.css-1n6sfyn-MenuList {
    max-height: 197px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 4px;
    padding-top: 4px;
    box-sizing: border-box;
}

.add_property_btn {
    top: 100px;
}

td.forward-and-contact-button {
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}

/* .form-user-add .form-user-add-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    row-gap: 20px;
    background: #fff;
    padding: 50px 30px;
    box-shadow: 0 0 16px #0000000d;
    z-index: 9;
    position: relative;
    border-radius: 20px;
    border: solid 1px transparent;
} */

.form-user-add .form-user-add-wrapper.birthday-section {
    z-index: unset;
}

.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap {
    width: 22%;
    display: flex;
    gap: 10px;
    font-size: 14px;
    /* border-bottom: 1px solid #fff; */
    padding-bottom: 4px;
    position: relative !important;
    flex-direction: column;
}

.form-user-add-wrapper.form-user-add-wrapper-lead-form .form-user-add-inner-wrap {
    width: 100%;
}

.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap img {
    position: absolute;
    left: 0%;
    bottom: 10px;
    height: 15px;
}


.form-user-add-inner-wrap .error-message {
    bottom: 13px;
    color: red;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    right: 28px;
}

.required-star {
    color: red;
}

.parent .family_meber_contact td {
    position: relative;
}

.parent .error-message {
    position: absolute;
    bottom: 14px;
    color: red;
    font-size: 12px;
    right: 10%;
    font-weight: 500;
}

.profile-page-form .error-message {
    position: absolute;
    /* top: 0px; */
    margin: 38px 104px;
    font-size: 12px;
}

.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap label {
    width: 100%;
    color: #000;
    font-weight: 700;
    font-size: 15px;
    margin-top: 26px !important;
    margin-bottom: -5px !important;
}

.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap input,
.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap .select-new {
    /* padding-left: 20px; */
    width: 100%;
    background-color: transparent !important;
    border: none;
    /* border-left: 1px solid #fff; */
    color: #858585;
    font-size: 12px;
    font-weight: 300;
    border-bottom: 0.5px solid #595555;
    padding-bottom: 0px;

    box-sizing: border-box;
    padding-right: 10px;
}

/* .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap .select-new {
    padding-left: 10px !important;
    padding-bottom: 7px !important;
} */

/* .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap .select-new {
    padding: 0px !important;
} */

.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap input {
    height: 22px;
}

.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap textarea {
    border: none;
    border-bottom: 0.5px solid #595555;
    height: 35px;
}

.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap textarea:focus-visible {
    outline: 0;
}

.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap input:-webkit-autofill {
    background-color: transparent;
}

.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap select {
    padding-left: 10px;
    width: 80%;
    background-color: transparent !important;
    border: none;
    border-bottom: 0.5px solid #595555;
    color: #858585;
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 7px;
}

.form-user-add .form-user-add-inner-btm-btn-wrap {
    margin-top: 20px;
    /* background: #000; */
    /* padding: 12px; */
    display: flex;
    justify-content: right;
    gap: 10px;
}

.form-user-add .form-user-add-inner-btm-btn-wrap button {
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #1F1E1D;
    padding: 14px 30px;
}

.form-user-add .form-user-add-inner-btm-btn-wrap button[type="submit"] {

    background: #004686;

}

.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap input:focus-visible,
.form-user-add .form-user-add-wrapper .form-user-add-inner-wrap select,
.search-group input,
.form-user-add-inner-wrap .select-new input {
    outline: unset;
}


/* second table */
.form-user-edit-inner-wrap {
    width: 100%;
    flex-wrap: wrap;
    gap: 0px;
    display: flex;
}

.select-new .Select-control {
    border: none;
    /* Remove the border */
    box-shadow: none;
    /* Remove the box shadow */
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap {
    width: 46%;
    display: flex;
    gap: 10px;
    font-size: 14px;
    /* border-bottom: 1px solid #fff; */
    padding-bottom: 4px;
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap label {
    width: 45%;
    color: #000;
    font-weight: 500;
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap .edit-new-input {
    padding-left: 10px;
    width: 100%;
    background-color: transparent !important;
    border: none;
    /* border-left: 1px solid #fff; */
    color: #858585;
    font-size: 14px;
    font-weight: 300;
    border-bottom: 0.5px solid #595555;
    padding-bottom: 7px;
    box-sizing: border-box;
}

.form-user-add .form-user-edit-inner-wrap .form-user-add-inner-wrap .select-new {
    padding-left: 8px !important;
    border-left: transparent;
}

.form-user-add-wrapper .form-user-add-inner-wrap .select-new {
    padding-left: 18px !important;
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap div.edit-new-input input,
.form-user-edit-inner-wrap .form-user-add-inner-wrap div.edit-new-input select,
.form-user-edit-inner-wrap .form-user-add-inner-wrap div.edit-new-input textarea {
    /* padding: 0 !important; */
    border-bottom: 0 !important;
    width: 100% !important;
    height: 22px;
    border: none;
    color: #000;
    background-color: transparent;
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap div.edit-new-input textarea:focus-visible {
    outline: 0;
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap div.edit-new-input {
    display: flex;
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap div.edit-new-input svg {
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap div.edit-new-input input {
    border: unset;
    font-size: 14px;
    font-weight: 500;
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap div.edit-new-input input:focus-visible {
    outline: unset;
}

.save-btn {
    background: #004686;
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    border: unset;
}

.cancel-btn {
    background: #1F1E1D;
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    border: unset;
}

.form-user-add-inner-btm-btn-wrap {
    justify-content: end;
    width: 100%;
    display: flex;
    gap: 10px;
}

.form-user-add-inner-btm-btn-wrap button {
    padding: 16px 30px;
    font-size: 16px;
    font-weight: 600;
}

.property-link {
    cursor: pointer;
    transition: .3s;
}

.property-link:hover {
    text-decoration: underline;
    color: #004686 !important;
}

/* .add-contact-from-adst .form-user-add-wrapper .form-user-add-inner-wrap input{
    padding-bottom: 2px !important;
    height: 44px !important;
} */

.add-contact-user-custom-wrapper {
    width: 100%;
    display: flex;
}

.add-contact-user-custom-right {
    width: 46%;
}

.add-contact-user-custom-left {
    width: 47%;
    padding-right: 3%;
}

.error-category {
    color: #c71515;
    font-weight: 600;
}

.heading-category {
    color: #fff;
    font-size: 19px;
    margin: 14px 12px 5px;
    padding-left: 7px;
    /* text-align: revert; */
}

.share-contact-comment {
    color: #fff;
    font-size: 13px;
    padding-left: 15px;
    font-weight: 600;
}

.delete-btn-ico {
    height: 19px;
    cursor: pointer;
    background-color: #fff;
}

.add-contact-user-custom-left .form-user-add-inner-wrap,
.add-contact-user-custom-right .form-user-add-inner-wrap {
    width: 100% !important;
}

.ck-label {
    display: none !important;
}

.setting-nav {
    width: 48px !important;
}

.setting-nav .background-group {
    width: 19px;
    height: 19px;
    right: -16%;
    left: auto;
}

.inner-pages-top-contacts {
    margin-bottom: 50px;
}

.inner-pages-top-contacts .search-group {
    width: 55% !important;
    margin-left: 0 !important;
}

.add_property_btn .inner-pages-top.inner-pages-top-contacts .add_user_btn {
    width: 100% !important;
    margin: 0 !important;
}

.add-contact-user-custom-left .form-user-add-inner-wrap {
    margin-bottom: 15px;
}

.add-contact-user-custom-right .form-user-add-inner-wrap .ck-content {
    height: 235px;
}

.add-contact-user-custom-right.add-contact-user-custom-right-edit .form-user-add-inner-wrap .ck-content {
    height: 160px;
}

.table-container .status-btn {
    width: auto !important;
}

.convert-lead-pop-up-content {
    width: 400px;
    height: 440px;
}

.convert-lead-pop-up-content form {
    flex-direction: column;
}

.convert-lead-pop-up-content form .modal-convert-btns {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.add_user_btn {
    width: 100%;
    box-sizing: border-box;
}

.share-text {
    margin-left: auto;
    margin-right: 0;
    gap: 10px;
    align-items: center;
    margin-top: 0 !important;
    font-size: 17px !important;
}


.camp-gap>input {
    width: 51%;
    margin-bottom: 13px;
    padding: 10px;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #c9c2c2;
    border-radius: 3px;
    margin-top: 40px;
}

.share-text svg path {
    fill: #004686;
}

.todo-sectionnew .todo-notes-section-new-left {
    width: 30% !important;
}

.todo-sectionnew .todo-notes-section-new-left .convert-lead-pop-up-content {
    width: 100%;
    height: 382px !important;
}

.todo-notes-section.todo-notes-section-new-right .ck-content {}

.todo-sectionnew .todo-notes-section-new-left .convert-lead-pop-up-content div {
    gap: 10px;
}

.todo-sectionnew .todo-notes-section-new-left .convert-lead-pop-up-content div form {
    width: 100%;
}

.todo-sectionnew .todo-notes-section-new-left .convert-lead-pop-up-content div form:first-child {
    gap: 0;
}

.todo-sectionnew .todo-notes-section-new-left .convert-lead-pop-up-content div form:first-child .heading-category {
    margin-bottom: 18px !important;
}


.todo-sectionnew .todo-notes-section-new-right {
    width: 70% !important;
}

.todo-sectionnew .todo-notes-section-new-right .ck.ck-editor__main {
    width: 100% !important;
}



.todo-sectionnew ul.custom-dropdown {
    margin: 0;
    padding-left: 0;
}

.todo-sectionnew .searchbar-grd,
.todo-sectionnew .css-b62m3t-container .css-13cymwt-control {
    background: linear-gradient(240deg, rgba(0, 72, 137, 1) 0%, rgba(0, 7, 44, 1) 100%);
    padding: 15px 6px 18px;
    border: none;
    margin-top: 12px;
}

.todo-sectionnew .css-b62m3t-container .css-13cymwt-control .css-1fdsijx-ValueContainer {
    padding: 0;
}

.todo-sectionnew .css-b62m3t-container .css-13cymwt-control .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder {
    color: #fff;
}

.todo-sectionnew .css-b62m3t-container .css-yqrvhi-menu {
    background: #ffffff;
    display: block;
    position: unset;
    margin-top: 0;
    border-radius: 0;
}

.todo-sectionnew .css-b62m3t-container .css-yqrvhi-menu .css-9mue6r-MenuList {
    padding: 0;
    display: flex;
    flex-direction: column;
}

.todo-sectionnew .css-b62m3t-container .css-yqrvhi-menu .css-9mue6r-MenuList .css-10wo9uf-option,
.todo-sectionnew .css-b62m3t-container .css-yqrvhi-menu .css-9mue6r-MenuList .css-d7l1ni-option {
    border-bottom: 1px solid #0000000d;
    padding: 12px 12px !important;
}

.todo-sectionnew .custom-dropdown {
    max-height: 243px;
}

.select-check-line {
    width: 100%;
    justify-content: space-between;
}

.select-check-line span {
    font-size: 14px;
    font-weight: 500;
}

.todo-sectionnew .searchbar-grd button {
    background: none !important;
    padding: 0;
    width: auto !important;
}

.todo-sectionnew .searchbar-grd input {
    padding: 0;
    background: no-repeat;
    font-size: 17px;
    font-family: 'Barlow', sans-serif;
    width: 90%;
    color: #fff;
    padding: 3px 0 2px;
}

.todo-sectionnew .searchbar-grd button {
    font-size: 20px !important;
    text-align: left;
}

.todo-sectionnew .searchbar-grd input::placeholder {
    opacity: 1 !important;
    color: #fff !important;
}

.todo-sectionnew .searchbar-grd input:focus-visible {
    border: none;
    stroke: 0;
}

.todo-sectionnew ul.custom-dropdown li {
    padding: 12px 12px;
    border-bottom: 1px solid #0000000d;
    display: flex;
    justify-content: space-between;
}

.todo-sectionnew ul.custom-dropdown li label {
    font-size: 14px;
    font-weight: 500;
}

.todo-sectionnew ul.custom-dropdown li input {
    width: 5%;
}

.custom-form-copy .heading-category {
    margin: 14px 12px 16px;
}

.todo-sectionnew .searchbar-grd {
    display: flex;
    flex-direction: column;
    position: relative;
}

.todo-sectionnew .searchbar-grd img {
    position: absolute;
    right: 8px;
    top: 19px;
}

.modal-roles-add.modal-roles-add-tw {
    box-shadow: 0px 2px 10px #00000042;
    width: 360px;
}

.pop-up-contact-form .css-yqrvhi-menu {
    position: relative;
    top: 0;
    margin: 0;
    border-radius: 0;
}

.pop-up-contact-form .css-yqrvhi-menu .css-9mue6r-MenuList {
    display: block;
}

.select-check-line-group-contacts {
    width: 250px;
}

.select-check-line-group-contacts h3 {
    background: #004686;
    margin: 0;
    padding: 17px 10px;
    color: #fff;
    font-size: 17px;
}

.select-check-line-group-contacts .listing {
    margin: 0;
    padding: 10px 10px;
    border-bottom: 1px solid #ebebeb;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.34) !important;
}

.custon-convert-select .css-1dimb5e-singleValue {
    color: #fff !important;
}

.leads-category-popup .custon-convert-select .css-yqrvhi-menu {
    max-height: 254px;
}

@media (max-width:1000px) {
    .react-confirm-alert-body {
        width: 256px !important;
    }

    .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap {
        width: 45%;
        justify-content: space-between;
    }

    .todo-sectionnew .todo-notes-section-new-left {
        width: 100% !important;
        z-index: 1;
    }

}

@media (max-width:768px) {

    .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap input,
    .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap .select-new {
        font-size: 15px;
    }

    .form-user-add .form-user-add-inner-btm-btn-wrap {
        justify-content: center;
    }

    .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap .address-toggle label,
    .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap .address-toggle input {
        width: unset !important;
        text-align: left;
    }

    .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap input {
        margin-right: 2px !important;
    }

    .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap label {
        margin-right: 6px;
    }

    .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap label:last-child {
        margin-right: 0;
    }

    .todo-sectionnew .todo-notes-section-new-left {
        width: 100% !important;
        z-index: 1;
    }

    .todo-sectionnew .todo-notes-section-new-right {
        width: 100% !important;
    }
}

@media (max-width:600px) {
    .form-user-add .form-user-add-wrapper {
        gap: 10px;
    }

    .form-user-add .form-user-add-wrapper .form-user-add-inner-wrap {
        width: 100%;
    }

    .property_header {
        padding-bottom: 25px;
        margin-bottom: 25px;
    }

    .form-user-edit-inner-wrap .form-user-add-inner-wrap {
        width: 100%;
    }

    .form-user-edit-inner-wrap {
        gap: 10px;
    }

    .add-user-new {
        margin-top: 0;
    }

    .add_user_btn button {
        padding: 10px 24px !important;
    }

    .add-user-new .form-user-add-inner-btm-btn-wrap button {
        margin-bottom: 0 !important;
    }

    .add-contact-user-custom-wrapper {
        flex-wrap: wrap;
    }

    .add-contact-user-custom-left,
    .add-contact-user-custom-right {
        width: 100%;
        margin: 0;
        flex-wrap: wrap;
    }

    .convert-lead-pop-up-content {
        width: 100%;
        padding: 0px !important;
    }

    .inner-pages-top-contacts .search-group {
        width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 20px;
    }

    .inner-pages-top-share-ref span.share-text {
        position: static !important;
    }

    .inner-pages-top-share-ref .search-group {
        margin: 10px 0 0 0 !important;
    }

    .add_property_btn .inner-pages-top-share-ref-tab .add_user_btn {
        width: 100% !important;
    }

    .add_property_btn .inner-pages-top.inner-pages-top-share-ref.inner-pages-top-share-ref-tab .add_user_btn {
        width: 100% !important;
    }

}

@media (max-width:480px) {
    .form-user-add .form-user-add-inner-btm-btn-wrap {
        flex-wrap: wrap;
    }

    .form-user-add .form-user-add-inner-btm-btn-wrap button {
        margin-right: 0;
        width: 80%;
        justify-content: center;
        margin-bottom: 70px;
    }
}


.container-chk {
    display: block;
    position: relative;
    padding-left: 17px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-chk input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.container-chk .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #0000001c;
    background-color: transparent;
}

/* On mouse-over, add a grey background color */
.container-chk:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-chk input:checked~.checkmark {
    background-color: rgb(0 70 134);
    border-color: #52a5f1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-chk:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-chk input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-chk .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    /* border: solid white;
    border-width: 0 3px 3px 0; */
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.listing {
    margin-left: 17px;
}

.become-klintale {
    height: auto !important;
    display: flex;
    width: auto !important;
    gap: 15px;
}

.become-klintale button {
    padding: 0;
}

.become-klintale button p {
    width: 100% !important;
    padding: 0 30px;
}

form.add-task-setion-form .form-user-add-wrapper .todo-section .todo-main-section .form-user-add-inner-wrap input,
form.form-user-add.form-add-lead .form-user-add-wrapper .form-user-add-inner-wrap input,
.add-cnt-form-desc .form-user-add-wrapper .form-user-add-inner-wrap input {
    color: #000000e8;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    border-left: transparent;
}

form.form-user-add.form-add-lead .form-user-add-wrapper .form-user-add-inner-wrap {
    width: 100%;
    margin: auto;
}

.add-cnt-form-desc .form-user-add-wrapper .add-contact-user-custom-wrapper .add-contact-user-custom-left {
    width: 100%;
    padding: 0;
}

.add-cnt-form-desc .add-contact-user-custom-right {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.add-cnt-form-desc .add-contact-user-custom-right .add-contact-user-custom-left {
    width: 100%;
}

.social-media-post-sec .form-user-add-inner-wrap .edit-new-input input {
    color: #000;
    font-size: 13px;
}

.add-contact-form .add-contact-user-custom-right .add-contact-user-custom-wrapper .form-user-add-inner-wrap label {
    padding: 0 8px 8px 0;
    display: inline-block;
}

.facebook-share-btn button.react-share__ShareButton {
    margin: 0;
}

.form-user-add-inner-wrap-user-edit-lead-pg {
    z-index: 99;
}

.form-user-add-inner-wrap-agent {
    z-index: 89;
}

.form-user-add-inner-wrap-bought {
    z-index: 75 !important;
}

.form-user-add-inner-wrap-interest {
    z-index: 70 !important;
}

.form-user-add-inner-wrap-budget {
    z-index: 65 !important;
}

.form-user-add-inner-wrap-describe {
    z-index: 60;
}

.form-user-add-inner-wrap-category {
    z-index: 55;
}

.form-user-add-inner-wrap-users {
    z-index: 99 !important;
}

.form-user-add-inner-wrap-add-lead-agent {
    z-index: 89 !important;
}

.form-user-add-inner-wrap-add-lead-source {
    z-index: 79 !important;
}

.form-user-add-inner-wrap-add-lead-category {
    z-index: 69 !important;
}

.form-user-agent-edit-contact {
    z-index: 99;
}

.form-user-service-edit-contact {
    z-index: 89;
}

.form-user-category-edit-contact {
    z-index: 79;
}

.add-contact-user-custom-left .form-user-add-inner-wrap .select-new input {
    color: #000;
}

.add-contact-user-custom-left .form-user-add-inner-wrap .select-new .css-1asdg40-control {
    /* background-color: unset; */
}

.add-contact-user-custom-left .form-user-add-inner-wrap .select-new .css-1asdg40-control .css-1jqq78o-placeholder {
    color: #000;
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap .css-1jqq78o-placeholder {
    color: #000;
}

.form-user-edit-inner-wrap .form-user-add-inner-wrap .edit-new-input input::placeholder {
    color: #000;
}

.leads-add-lead-form .form-user-add-wrapper .form-user-add-inner-wrap input::placeholder {
    color: #000;
}

.leads-add-lead-form .form-user-add-wrapper .form-user-add-inner-wrap .css-1jqq78o-placeholder {
    color: #000;
}

form.add-contact-form .form-user-add-inner-wrap .edit-new-input input::placeholder {
    color: #000;
}

form.add-contact-form .add-cnt-form-desc .add-contact-user-custom-right .add-contact-user-custom-wrapper .form-user-add-inner-wrap .select-new {
    border-bottom: 1px solid #000;
}

form.add-contact-form .add-cnt-form-desc .add-contact-user-custom-right .add-contact-user-custom-wrapper .form-user-add-inner-wrap .select-new .css-1y9lc05-control {
    background: unset;
}

form.add-contact-form .add-cnt-form-desc .add-contact-user-custom-right .add-contact-user-custom-wrapper .form-user-add-inner-wrap .select-new .css-1y9lc05-control .css-1jqq78o-placeholder {
    color: #000;
}

.form-user-add-inner-wrap-add-contact-service {
    z-index: 999999;
    position: relative;
}

.form-user-add-inner-wrap-add-contact-agent {
    z-index: 99999999;
    position: relative;
}

.add-vendor-form .form-user-add-wrapper .form-user-add-inner-wrap {
    width: 100%;
}

.add-vendor-form .form-user-add-inner-btm-btn-wrap button {
    background: #004686;
}

.todo-main-section.todo-notes-section-new-left .sms-list-form form .css-yqrvhi-menu .select-check-line svg {
    margin-right: 5px;
}

.leads-category-popup {
    position: absolute;
}

.leads-category-popup .css-yqrvhi-menu {
    overflow: hidden;
    max-height: 272px;
}

.leads-category-popup .css-yqrvhi-menu div#react-select-3-listbox {
    overflow: auto;
}


.inner-pages-top-share-ref .search-grp-with-btn .add_user_btn {
    height: 48px;
}

.leads-category-popup .modal-roles-add div button {
    width: 40%;
}

.search-grp-with-btn {
    display: flex;
    align-items: start;
    justify-content: space-between;
    column-gap: 10px;
}

.search-grp-with-btn .search-group {
    margin: unset !important;
    width: 60% !important;
    height: 46px !important;
    top: 0;
}

.search-grp-with-btn .add_user_btn {
    margin: 0 !important;
}

.search-grp-with-btn .add_user_btn button {
    margin: 0 !important;
    height: 46px !important;
}

.search-grp-with-btn input {
    border: 1px solid #00000038 !important;
    height: 100% !important;
}

.search-grp-with-btn .add_user_btn button {
    height: 100%;
}


@media (max-width:600px) {
    .search-grp-with-btn {
        margin-top: 20px;
        width: 100%;
    }

    .add_property_btn .inner-pages-top span.share-text {
        position: static !important;
        padding-bottom: 10px;
    }

    .inner-pages-top-share-ref .search-grp-with-btn .add_user_btn button {
        padding: 0 30px !important;
    }

    .inner-pages-top-contacts-duplicate .search-grp-with-btn {
        margin-bottom: 20px;
    }

    .table-container.family_meber_contact table {
        width: 800px;
    }
}

.react-confirm-alert-body {
    width: 400px !important;
}

.search-yellow-highlight {
    background-color: yellow;
}

.add-contact-user-custom-left .form-user-service-edit-contact {
    z-index: 99999;
}


.main-div-message-list-parent {
    display: flex;
    flex-direction: column;
    background-color: #fff;

}

.main-div-messages-list {
    margin: 8px;
    border-radius: 25px;
    border: 2px solid #004686;
    display: flex;
    flex-direction: column;
    padding: 2px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.single-chat-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
}

.username {
    font-weight: bold;
    font-size: 16px;
    color: #333;
    flex: 1;
}

.message-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.latest-message {
    color: #161515 2px bold;
    font-size: 14px;
    margin-top: 6px;
}

.latest-unread-message {

    color: #161515;
    font-size: 14px;
    font-weight: bold;
    margin-top: 6px;
    /* Top margin */

}

.unread-message {
    font-size: 12px;
    color: #004686;
    background-color: #eafaf1;
    padding: 2px 6px;
    border-radius: 12px;
}

.main-div-messages-list:hover {
    background-color: #f6f6f6;
    cursor: pointer;
}



.main-chat-div {
    margin-top: 10px;
    border-radius: 15px;
    height: 500px;
    border: 2px solid #ccc;
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
}

.user-detail-title-info {
    background-color: #004686;
    padding: 10px;
    display: flex;
    border-radius: 15px 15px 0px 0px;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    color: #fff;
}

.messages-div {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #9c9898;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: column;
   
}

.message-div {
   
    margin-top:2px;
    margin-bottom: 2px;
    padding: 7px;
    border-radius: 10px;
    background-color: #fff;
    max-width: 80%;
    word-wrap: break-word;
    align-self: flex-start;
}

.receiver-div {
    align-self: flex-end;
    background-color: #e1ffc7;
}

.sender-div {
    align-self: flex-start;
    background-color: #d1d1d1;
}

.message-div small {
    display: block;
    margin-top: -13px;
    color: #666;
}

.message-box {
    display: flex;
    padding: 2px;
    background-color: #e2dbdb;
    border-top: 1px solid #ddd;
}

.message-box textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: none;
}

.message-box button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #004686;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.message-box button:hover {
    background-color: #0056b3;
}