.background5,
.separator {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--theme-color-grey-3);
  width: 1px;
  height: 56px;
}
.background5 {
  left: calc(50% - 698.5px);
  background-color: var(--light-theme-color-light);
  border: 1px solid var(--color-whitesmoke);
  box-sizing: border-box;
  width: 866px;
}
.search-icon1 {
  position: absolute;
  top: 1px;
  left: calc(50% + 381.5px);
  width: 23.99px;
  height: 24px;
}
.search-here1 {
  position: absolute;
  top: 4px;
  left: calc(50% - 405.5px);
}

.cursor {
  top: 0;
  left: calc(50% - 405.5px);
  background-color: var(--theme-color-info);
  width: 2px;
}
.search-group {
    width: 51%;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: #FDFDFD;
    height: 47px;
    margin-top: -9px;
    position: relative;
    top: 7px;
    display: flex;
    align-items: center;
    margin-left: 70px;
}


.search-group input {
  border: none;
  font-size: 15px;
  height: 46px;
  margin-left: 0px;
  width: 100%;
  padding: 0px 15px;
  background: none;
}

.search-group img{
    position: relative;
    right: 5px;
}

.add_property_btn{
    padding-top: 50px;
}

.add_property_btn .inner-pages-top .add_user_btn{
  width: unset !important;
  margin-left: 0;
  margin-right: auto;
}

.inner-pages-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inner-pages-top h3{
    margin: 0;
    color: #000;
    font-size: 32px;
}

.inner-pages-top .add_user_btn{
    margin: 0;
}

.inner-pages-top .add_user_btn button{
    margin: 0;
    display: flex;
    align-items: center;
    gap: 7px;
    border-radius: 5px;
    margin-left: 57px;
    font-size: 15px;
    font-weight: 500;
}

.inner-pages-top .search-group{
    width: 22%;
    height: 35px;
    margin-left: 70px;
    margin-top: -13px;
}

.inner-pages-top .search-group input{
    height: 35px;
    font-size: 13px;
    border-radius: 5px ;
}

.inner-pages-top .search-group img{
    right: 10px;
    width: 16px;
}

.samantha {
  font-weight: 600;
}

.hello-samantha{
  display: flex;
}

.hello-samantha,
.profile3 {

}
.profile3 {
 
}
.avatar3,
.placeholder-icon3 {

}
.placeholder-icon3 {
  width: 57px;
  border-radius: var(--br-44xl);
  max-width: 57px;
  overflow: hidden;
  max-height: 57px;
  object-fit: cover;
  height: 57px;
  background: #000;
}
.avatar3 {
  width: 100%;
}
.profile-parent {
  color: var(--color-peru-100);
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  top: 0px;
  right: 0;
  padding-left: 40px;
}

.profile-parent > a{
  width: 57px;
}

.icon-dashboard-child,
.icon-dashboard-item {
  position: absolute;
  top: 6px;
  left: 0;
  border-radius: var(--br-mini);
  background-color: var(--color-peru-200);
  width: 48px;
  height: 48px;
}
.icon-dashboard-item {
  left: 72px;
}
.icon-dashboard1,
.icon-dashboard2 {
  position: absolute;
  top: 16px;
  left: 10px;
  width: 28px;
  height: 28px;
}
.icon-dashboard2 {
  left: 82px;
  overflow: hidden;
}
.background6 {
  position: absolute;
  height: 131.58%;
  width: 131.58%;
  top: -15.79%;
  right: -15.79%;
  bottom: -15.79%;
  left: -15.79%;
  border-radius: var(--br-29xl);
  background-color: var(--color-peru-100);
  border: 3px solid var(--light-theme-color-grey-2);
  box-sizing: border-box;
}
.div3 {
  position: absolute;
  height: 89.47%;
  width: 68.42%;
  top: 5.26%;
  left: 15.79%;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.background-group {
  position: absolute;
  height: 35.19%;
  width: 15.83%;
  top: 0;
  right: 55%;
  bottom: 64.81%;
  left: 29.17%;
}
.icon-dashboard {
  position: relative;
  width: 120px;
  height: 54px;
  text-align: center;
  font-size: var(--body-paragraph-small-size);
  color: var(--theme-color-white);
}
.top-navbar {
  width: 100%;
  height: 56px;
  text-align: left;
  font-size: var(--body-paragraph-lead-size);
  color: var(--color-darkgray-100);
  font-family: var(--heading-heading-4);
  position: relative;
  display: flex;
  justify-content: end;
  gap: 32px;
}
.profile-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #004686;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 0;
  width: 180px;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

.profile-menu a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
}

.profile-menu a:hover {
  background: #fff;
  color: #004686;

}
.camp-gap{
  margin-top: 13px;
}
/* Existing styles */

.profile-menu::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 10px;
  color: #004686;
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;  
  border-bottom: 10px solid #004686;
}

.inner-pages-top-contacts.inner-pages-top-contacts-duplicate {
  flex-wrap: wrap;
}

.inner-pages-top-contacts.inner-pages-top-contacts-duplicate h3 {
  width: fit-content;
}

.add_property_btn .inner-pages-top-contacts.inner-pages-top-contacts-duplicate  .add_user_btn {
  width: auto !important;
  margin: 0 !important;
  gap: 15px;
}

.add_property_btn .inner-pages-top-contacts.inner-pages-top-contacts-duplicate .add_user_btn button {
  margin: 0 !important;
}

.add_property_btn .inner-pages-top-contacts.inner-pages-top-contacts-duplicate .add_user_btn button {
  margin: 0;
}

.inner-pages-top-contacts.inner-pages-top-contacts-duplicate .search-group {
  width: auto !important;
  margin-left: 0 !important;
}



@media (max-width:600px){
  .add_property_btn .inner-pages-top-contacts.inner-pages-top-contacts-duplicate span.share-text {
    width: 95%;
}

.inner-pages-top-contacts.inner-pages-top-contacts-duplicate .search-group {
  width: 100% !important;
}
.add_property_btn .inner-pages-top-contacts.inner-pages-top-contacts-duplicate .add_user_btn button {
  margin: 0 20px 0px 0 !important;
}

}

.custom-search-btn-btn-search{
  background-color: #004686 !important;
  border: 2px solid #004686 !important;
  border-radius: 2px;
  color: white !important;
}