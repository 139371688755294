/* CSS for the table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

thead {
  background-color: #f2f2f2;
}

th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 14px;
}

.table-container table thead tr th,
.table-container table tbody tr td {
  border: 1px solid #000;
}

.table-container table tbody tr td {
  padding-bottom: 30px;
  padding-top: 12px;
}

th {
  color: white;
}

.new-tag-date th {
  color: black;
}

/* CSS for the buttons */
.add_user_btn button {
  background-color: #004686;
  color: white;
  border: none;
  padding: 14px 30px;
  cursor: pointer;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  border: 2px solid #004686;
  border-radius: 4px;
}

.add_user_btn button:hover {
  background-color: #000;
  color: #ffffff;
  border: 2px solid #000000;
  transition: all 0.3s;
}

button:hover {
  background-color: #45a049;
}

/* Center the buttons horizontally in the table cell */
td button {
  display: inline-block;
  margin: 0 auto;
}

/* Add some margin to the table container */
.table-container {
  margin-top: 20px;
}

.add_user_btn {
  margin: 20px 50px 50px 50px;
}

.menu .add_user_btn {
  width: 100%;
  text-align: center;
  margin-top: 30px !important;
  margin: 0;
}

.menu .bm-cross-button {
  right: 18px !important;
  top: 13px !important;
}

.menu .add_user_btn button {
  padding: 8px 20px;
  font-size: 16px;
}

.user_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_bar label {
  font-size: 14px;
  padding-right: 10px;
}

.bm-menu nav a {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}

.bm-menu nav button {
  background-color: #004889;
  margin: 0;
  padding: 7px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  border: 3px solid #004889;
  color: #fff;
  border-radius: 4px;
}

.add_property_btn .table-container {
  background: #004686;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 25px;
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.12);
}

.add_property_btn .table-container table {
  margin-top: 0;
  ;
}

.add_property_btn .table-container table tr {
  background: #004686;
}

.add_property_btn .table-container table tr th {

  font-size: 13px;
  font-weight: 600;
  padding: 10px 6px !important;
  text-align: left !important;
}

.add_property_btn .table-container table tr td,
.add_property_btn .table-container table tr th {
  border: none;
  padding: 0;
}

.listing {
  width: auto;
}

.add_property_btn .table-container table tr td {
  font-size: 13px;
  font-weight: 500;
  padding: 15px 6px !important;
  color: #000;
}

.add_property_btn .table-container table tbody tr {
  background: #fff;
  border-bottom: 1px solid #00000014;
}

.add_property_btn .table-container table tbody tr:last-child {
  border: none;
}

.add_property_btn .table-container table tr td:first-child,
.add_property_btn .table-container table tr th:first-child {
  padding-left: 15px !important;
}

.add_property_btn .table-container table tr td:last-child,
.add_property_btn .table-container table tr th:last-child {
  padding-right: 15px !important;
}

/* 
.listing {
  text-align: center;
} */

.price {
  width: 80px;
}

th.price {
  text-align: center;
}

.add_property_btn .table-container table tbody tr .permission-tick-block-wraper {
  display: flex;
  gap: 20px;
}

.add_property_btn .table-container table tbody tr .permission-tick-block-wraper .permission-tick-block {
  display: flex;
  align-items: center;
  gap: 6px;
}

.add_property_btn .table-container table tbody tr .table-action-btn {
  display: flex;
  justify-content: left;
  gap: 10px;
}

.table-action-btn .edit-btn,
.table-action-btn .delet-btn {
  margin: 0;
  background: #000;
  color: #fff;
  border: none;
  font-size: 11px;
  padding: 4px 10px;
  border-radius: 3px;
}

.table-action-btn .edit-btn {
  background: #0a4f93;
}

.table-action-btn .delet-btn {
  background: #af080c;
}

.modal-roles-add {}

.modal-roles-add form {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  background: #004686;
}

.modal-roles-add form input {
  width: 100%;
  background: #fff;
  border: none;
  padding: 9px 10px;
}

.pop-up-content-category {
  background: #fff;
}

.pop-up-content-category form .modal-convert-btns {
  position: absolute;
  bottom: 0;
  padding: 15px 0px;
  left: 0;
  max-width: 100%;
  width: -webkit-fill-available;
  border-top: 1px solid #0000001f;
  background: #00000014;
  z-index: 99999;
  width: 100%;
}

.send-msg-grp-popup form .modal-convert-btns {
  width: 100%;
}

.modal-roles-add form {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.modal-roles-add form input {
  width: 100%;
  background: #fff;
  border: none;
  padding: 9px 10px;
}

.modal-roles-add form button {
  width: 49%;
  background: #004686;
  border: none;
  color: #fff;
  padding: 11px 0;
  border-radius: 5px;
}

.modal-roles-add form button:last-child {
  background: #004686;
  font-size: 14px;
  font-weight: 700;
}

.close-modal-share {
  position: absolute;
  cursor: pointer;
  right: 19px;
  width: 20px;
  /* filter: invert(1); */
  transform: rotate(45deg);
  top: 17px;
}

.inquiries-details-pop-up .category-box {
  padding: 20px 15px;
}

div#Contact-s img {
  background: transparent !important;
  right: -10px;
  top: -6px;
  transform: rotate(90deg) !important;
}

.inquiries-details-pop-up .category-box>div {
  margin-bottom: 10px;
}

.category-btn {
  padding: 15px;
  text-align: center;
  border-top: 1px solid #0000001f;
  background: #00000014;
}

.category-textarea {
  margin-top: 10px;
}

.category-textarea textarea {
  resize: none;
}

.category-btn button {
  border-radius: 6px;
  font-size: 16px;
}


.inquiries-details-pop-up .category-box label {
  font-size: 18px;
  font-weight: 500;
}

.close-modal-share-span {
  transform: rotate(0deg) !important;
  font-weight: 600;
}

.inquiries-details-pop-up form div:nth-child(4) {
  text-align: center;
}

.inquiries-details-pop-up .heading-category {
  margin: 0px !important;
  padding: 15px;
  background-color: #004686;
}

.inquiries-details-pop-up form div:nth-child(3) button {
  width: 30%;
  background: #004686;
  border: none;
  color: #fff;
  padding: 11px 0;
}

/* ===================modal css=================== */
.modal-roles-add {}

.modal-roles-add form {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

}

.modal-roles-add form input {
  width: unset;
  background: #fff;
  border: none;
  padding: 15px 10px;
}

.modal-roles-add form button {
  width: 49%;
  background: #004686;
  border: none;
  color: #fff;
  padding: 11px 0;
}

/* .modal-roles-add div {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
} */
.modal-roles-add div button {
  width: 49%;
  background: #004686;
  border: none;
  color: #fff;
  padding: 11px 0;
}

.modal-roles-add div input {
  width: 100%;
  background: #fff;
  border: none;
  padding: 9px 10px;
}

.modal-roles-add form button:last-child {
  background: #004686;
}

.download-file {
  position: relative;
  padding: 0px 25px 18px 25px !important;
  background: #fff;
  overflow: hidden;
}

.download-file button.close-btn {
  position: absolute;
  top: 2px;
  right: -1px;
  background-color: unset;
  border: unset;
  rotate: 45deg;
}

.bulk-upload-popup {
  width: 350px;
}

.bulk-upload-popup {
  width: 350px;
  padding-top: 20px !important;
}

.download-file button.close-btn img {
  filter: brightness(0) saturate(100%);
}

.file-upload {
  border: 2px dotted #000000;
  background-color: #fff;
  position: static;
  width: 99%;
  display: flex;
  justify-content: center;
  padding: 6px 0;
  border-radius: 4px;
}

.camp-gap .share-ref-top-wrp button {
  background: #004686;
  color: #fff;
  margin-top: 20px;
}

.camp-gap .share-ref-top-wrp p {
  width: 100% !important;
  color: #fff;
  text-align: center;
}

/* ===================modal css=================== */





.custom-dropdown {
  display: flex;
  flex-direction: column;
  max-height: 210px;
}

.custom-option {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  cursor: default;
  /* display: block; */
  font-size: inherit;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  color: inherit;
  padding: 12px 12px;
  box-sizing: border-box;
  border-bottom: 1px solid #0000000d;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #0000001c;
  /* Ensures the element is a perfect circle */
  /* Change this to set the circle's color */
}

.custom-option.selected .circle {

  background-color: rgb(0 70 134);
  border-color: #52a5f1;
}

.custom-option label {
  font-size: 14px;
  font-weight: 500;
}

.custom-option :hover {
  background-color: #B2D4FF;
}

.custom-option input {
  margin-right: 8px;
  padding: unset !important;
  width: unset !important;
}

/* .ReactModal__Overlay{
  background-color: rgb(0 0 0 / 75%);
}

.ReactModal__Overlay .ReactModal__Content{
  background: #000 !important;
  border: 1px solid #fff;
} */

@media (max-width:1500px) {

  table tr td,
  table tr th {
    font-size: 15px !important;
  }

  .add_property_btn .table-container table tr th,
  .add_property_btn .table-container table tr td {

    font-size: 11px;
  }

  .main-dashbord-wrapper .main-sidenav-wrapper .side-menu .menu a {
    font-size: 15px;
  }
}


.add_user_btn-mng-cnfgr-pg {
  margin: 0;
}

.add_user_btn-mng-cnfgr-pg .add_user_btn {
  margin: 0;
}

.manage-configr-table table {
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.manage-configr-table table th {
  border: unset !important;
}

.manage-configr-table table td {
  border: unset !important;
}

.manage-configr-table table td {
  color: #000;
  font-size: 14px !important;
}

td.manage-pg-table-confgr {
  width: 60%;
  padding-right: 30px;
}

.add_user_btn-mng-cnfgr-pg button img {
  margin-right: 8px;
}

td.manage-configr-table-btn {
  width: 110px;
}

td.manage-configr-table-btn buttton {
  background: #004686;
  color: #fff;
  padding: 7px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.modal-roles-add.send-msg-grp-popup img.close-modal-share {
  cursor: pointer;
}

.modal-roles-add.send-msg-grp-popup form .select-new .css-ao180k-control .css-1hb7zxy-IndicatorsContainer svg {
  cursor: pointer;
}

.add_property_btn-leads-page .add_property_btn .table-container table {
  margin-bottom: 0 !important;
}

@media (max-width:600px) {
  .manage-configr-table table {
    width: 900px;
  }

  .pop-up-content-category form .modal-convert-btns {
    width: 100%;
  }

  .send-msg-grp-popup form .modal-convert-btns {
    width: 88%;
    background: #ebebeb;
  }

  .bulk-upload-popup {
    width: auto;
  }

  .ReactModalPortal .pop-up-add-configure .modal-convert-btns {
    width: 88%;
    background: #ebebeb;
  }

}