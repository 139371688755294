.spinner {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0px auto;
    top: 50%;
    left: 50%;
    background-color: #ddddddc7;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.spinner-inner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3498db;
    animation: spin 1s linear infinite;
}
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  