/* Navbar.css */

.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:17px 50px;
    background-color: #262629c2;
  }
  .links-handle {
    display: flex;
    gap: 40px;
    }
    .links-handle li a {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
    }
  .nav-links li {
    margin: 0 15px;
  }
  
  ul.nav-links button:hover {
    background-color: #c59a4a;
    color: #fff;
    transition: all 0.3s;
}

  ul.nav-links button {
    padding: 10px 12px;
    border-radius: 4px;
    font-size: 14px;
    color: #c59a4a;
    font-weight: 600;
}

  .nav-links li a {
    text-decoration: none;
  
  }
  
  .nav-links li a:hover {
    color: #c59a4a;
  }
  .footer-logo{
    object-fit: cover;
  }
  .links-footer li {
    margin-bottom: 10px;
  }
  .links-footer li a {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
  }
  .links-footer {
    width: 30%;
  }

  .nav_footer {
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 17px 50px;
    background-color: #262629c2;
    row-gap: 30px;
  }

  .links-footer {
    width: auto;
    display: flex;
    gap: 40px;
  }

  .links-footer li a:hover {
    color: #004889;
    transition: all 0.3s;
}
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  top: 50px;
  left: 26px;
  color: #004889 !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  z-index: 999999999999999999 !important;
}

span.bm-cross {
  height: 20px !important;
  width: 4px !important;
  background-color: #fff !important;
}

/* General sidebar styles */
.bm-menu {
  background: linear-gradient(180deg, rgba(0,72,137,1) 0%, rgba(0,7,44,1) 100%);

  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #ffffff;
}
