
section.inner-sec-stats {
    padding: 0 0 40px 0;
}
td.fc-day-past{
    opacity: 0.3;
}
.heading {
    margin: 25px 0;
    font-size: 24px;
}
.dashboard-stat {
    position: relative;
    display: block;
    margin: 0 0 25px;
    overflow: hidden;
    border-radius: 4px;
}
.dashboard-stat .visual {
    width: 80px;
    height: 80px;
    display: block;
    float: left;
    padding-top: 10px;
    padding-left: 15px;
    margin-bottom: 15px;
    font-size: 35px;
    line-height: 35px;
}
.dashboard-stat .visual > i {
    margin-left: -15px;
    font-size: 110px;
    line-height: 110px;
    color: #fff;
    opacity: .1;
}
.dashboard-stat .details {
    position: absolute;
    right: 15px;
    padding-right: 15px;
    color: #fff;
}
.dashboard-stat .details .number {
    padding-top: 25px;
    text-align: right;
    font-size: 34px;
    line-height: 36px;
    letter-spacing: -1px;
    margin-bottom: 0;
    font-weight: 300;
}
.dashboard-stat .details .number .desc {
    text-transform: capitalize;
    text-align: right;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 300;
}
.dashboard-stat.blue {
    background-color: #337ab7;
}
.dashboard-stat.red {
    background-color: #e7505a;
}
.dashboard-stat.purple {
    background-color: #8E44AD;
}
.dashboard-stat.hoki {
    background-color: #67809F;
}
.stats-heading h3 {
    font-size: 22px;
    color: #004686;
    margin: 0;
}
.stats-heading span {
    font-size: 16px;
    color: #424141;
}
.stats-parent-section {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.stats-sec {
    background: #fff;
    padding: 14px;
    border-radius: 4px;
    width: 21%;
}
.stats-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.stats-order span {
    font-size: 16px;
    font-weight: 500;
}
span.order-numbers {
    font-size: 20px;
    color: #6c6969;
}
.stats-percentage {
    background: #dfedff;
    height: 5px;
    border-radius: 8px;
    overflow: hidden;
}
.stats-percentage {
    background: #dfedff;
    height: 5px;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 25px;
    margin-bottom: 7px;
}
.total-stats-perc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.total-stats-perc span {
    color: #3e3e3e;
    font-size: 13px;
    font-weight: 500;
}
.stats-inner-perc {
    height: 100%;
    background: #003c78;
    width: 80%;
}

@media (max-width:600px){
    .stats-parent-section {
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .stats-sec {
        width: 100%;
    }
}